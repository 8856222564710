import { Notification } from "@mantine/core";
import {
	useNotificationStore,
	remove as removeNotification,
} from "../store/notifications";
import { useEffect, useState } from "react";
import { useInterval } from "@mantine/hooks";

export const NotificationsManager = () => {
	const [lifetimes, setLifetimes] = useState<
		{ id: string; expiresAt: number }[]
	>([]);
	const notifications = useNotificationStore(store => store.notifications);

	const interval = useInterval(() => {
		const iNotifications = notifications.filter(n => !!n.lifetime);

		// Create new notificationLifetimes
		const newNotificationLifetimes = iNotifications
			.filter(
				notification =>
					!lifetimes.find(nl => nl.id === notification.id),
			)
			.map(n => ({
				id: n.id,
				expiresAt: Date.now() + (n.lifetime || 0),
			}));

		// Remove expired notifications
		const prunedNotifications = lifetimes.filter(
			nl => Date.now() >= nl.expiresAt,
		);

		prunedNotifications.map(pn => removeNotification(pn.id));

		// Set new state
		setLifetimes(lifetimes => [
			...lifetimes.filter(
				nl => !prunedNotifications.find(pn => pn.id === nl.id),
			),
			...newNotificationLifetimes,
		]);
	}, 300);

	useEffect(() => {
		interval.start();

		return () => interval.stop();
	}, [interval]);

	return (
		<div
			style={{
				position: "fixed",
				left: 0,
				bottom: 0,
				width: "400px",
				maxWidth: "100%",
				padding: "20px",
				zIndex: 10000,
				pointerEvents: notifications.length === 0 ? "none" : "inherit",
			}}
		>
			{notifications.map((n, i) => {
				const hasMargin = notifications.length > 1 && i > 0;

				return (
					<Notification
						withBorder
						key={n.id}
						onClose={() => removeNotification(n.id)}
						mt={hasMargin ? "md" : undefined}
						title={n.title}
						color={n.color}
						icon={n.icon ? n.icon : undefined}
					>
						{n.message}
					</Notification>
				);
			})}
		</div>
	);
};
