import { PeriodSwitcher } from "../../components/period-switcher/period-switcher";
import { Export } from "./export";
import classes from "./navigation.module.css";

interface INavigation {
	selectedDate: Date;
	onChange: (date: Date) => void;
}

export const Navigation = ({ selectedDate, onChange }: INavigation) => {
	return (
		<div className={classes.tableHeader}>
			<PeriodSwitcher
				value={selectedDate}
				period={"month"}
				onChange={onChange}
			/>
			<Export date={selectedDate} period={"month"} />
		</div>
	);
};
