import {
	Box,
	Collapse,
	Group,
	Text,
	ThemeIcon,
	UnstyledButton,
} from "@mantine/core";
import { type Icon, IconChevronRight } from "@tabler/icons-react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./links-group.module.css";

interface LinksGroupProps {
	icon: Icon;
	label: string;
	initiallyOpened?: boolean;
	link?: string;
	links?: { label: string; link: string }[];
}

export function LinksGroup({
	icon: Icon,
	label,
	initiallyOpened,
	links,
	link,
}: LinksGroupProps) {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const hasLinks = Array.isArray(links);
	const [opened, setOpened] = useState(initiallyOpened || false);
	const items = (hasLinks ? links : []).map(link => (
		<Text<"a">
			component="a"
			className={classes.link}
			href={link.link}
			key={link.label}
			onClick={event => event.preventDefault()}
		>
			{link.label}
		</Text>
	));

	const active = pathname === link;

	return (
		<>
			<UnstyledButton
				onClick={() => {
					if (hasLinks) {
						setOpened(o => !o);
						return;
					}

					if (link) {
						navigate(link);
						return;
					}
				}}
				className={classes.controlasdasdasd}
				style={active ? { color: "#a5d8ff" } : {}}
			>
				<Group justify="space-between" gap={0}>
					<Box style={{ display: "flex", alignItems: "center" }}>
						<ThemeIcon variant="light" size={30}>
							<Icon size={20} />
						</ThemeIcon>
						<Box ml="md">{label}</Box>
					</Box>
					{hasLinks && (
						<IconChevronRight
							className={classes.chevron}
							size={18}
							stroke={1.5}
							style={{
								transform: opened ? "rotate(-90deg)" : "none",
							}}
						/>
					)}
				</Group>
			</UnstyledButton>
			{hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
		</>
	);
}
