import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Anchor, Container, Tabs } from "@mantine/core";
import type { Customer as ApiCustomer } from "../../generated";
import classes from "./customer.module.css";
import { getCustomer } from "../../api/customers";
import { Projects } from "./projects/projects";
import { Contacts } from "./contacts/contacts";
import { Info } from "./info";
import { CreateOrEdit } from "./create-or-edit";

export const Customer = () => {
	const navigate = useNavigate();
	const { page, customerId } = useParams();

	const [customer, setCustomer] = useState<ApiCustomer>();

	useEffect(() => {
		if (!customerId) return;

		getCustomer(customerId).then(setCustomer);
	}, [customerId]);

	if (!customer || !customerId) return;

	if (page === "edit") return <CreateOrEdit customerId={customerId} />;

	return (
		<div className={classes.wrapper}>
			<div className={classes.header}>
				<h1>{customer.name}</h1>
				<div className={classes.headerListItem}>
					<div>Website</div>
					<Anchor href={customer.domain}>{customer.domain}</Anchor>
				</div>
			</div>
			<div className={classes.tabWrapper}>
				<Tabs
					keepMounted={false}
					value={page || "info"}
					onChange={value =>
						navigate(`/customersv2/${customer.id}/${value}`)
					}
					classNames={{
						root: classes.root,
						tab: classes.tab,
						panel: classes.panel,
					}}
					variant="outline"
					defaultValue="reports"
				>
					<Tabs.List justify="center">
						<Tabs.Tab value="info">Info</Tabs.Tab>
						<Tabs.Tab value="contacts">Contacts</Tabs.Tab>
						<Tabs.Tab value="projects">Projects</Tabs.Tab>
						<Tabs.Tab value="settings">Settings</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel value="info">
						<Container fluid p="xl">
							<Info customerId={customerId} />
						</Container>
					</Tabs.Panel>
					<Tabs.Panel value="contacts">
						<Container fluid p="xl">
							<Contacts />
						</Container>
					</Tabs.Panel>
					<Tabs.Panel value="projects">
						<Container fluid p="xl">
							<Projects />
						</Container>
					</Tabs.Panel>
					<Tabs.Panel value="settings">
						<Container fluid p="xl">
							<CreateOrEdit customerId={customerId} />
						</Container>
					</Tabs.Panel>
				</Tabs>
			</div>
		</div>
	);
};
