import { Box, Center } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import {
	addMonths,
	addWeeks,
	format,
	getWeek,
	subMonths,
	subWeeks,
} from "date-fns";
import type { Period } from "../../../types";
import classes from "./period-switcher.module.css";

interface IPeriodSwitcher {
	value: Date;
	period: Period;
	onChange: (value: Date) => void;
}

export const PeriodSwitcher = ({
	value,
	period,
	onChange,
}: IPeriodSwitcher) => {
	const handleAddPeriod = () => {
		if (period === "month") {
			onChange(addMonths(value, 1));
			return;
		}
		onChange(addWeeks(value, 1));
	};

	const handleSubPeriod = () => {
		if (period === "month") {
			onChange(subMonths(value, 1));
			return;
		}
		onChange(subWeeks(value, 1));
	};

	return (
		<div className={classes.container}>
			<IconArrowLeft onClick={handleSubPeriod} className={classes.icon} />
			<Box w={125}>
				<Center>
					{period === "week"
						? `Week ${getWeek(value)}`
						: format(value, "LLLL yyy")}
				</Center>
			</Box>
			<IconArrowRight
				onClick={handleAddPeriod}
				className={classes.icon}
			/>
		</div>
	);
};
