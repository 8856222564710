import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../store/auth";

export const RequireAuth = () => {
	const {
		state: { isAuthenticated },
	} = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) navigate("/login");
	}, [isAuthenticated, navigate]);

	if (isAuthenticated) return <Outlet />;
};
