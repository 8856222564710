import {
	areIntervalsOverlapping,
	endOfMonth,
	endOfWeek,
	isSameDay,
	parseISO,
	startOfMonth,
	startOfWeek,
} from "date-fns";
import { timeReportStore, type View } from "./store";
import { getDates } from "../report/helpers";
import { betweenDates } from "../../utils/ledig/public-holiday";

export type DateRange = { date: Date; dayOff: boolean; dayOffName?: string };

export const getSelectedDates = (date: Date, view: View) => {
	switch (view) {
		case "month":
			return {
				startDate: startOfMonth(date),
				endDate: endOfMonth(date),
			};
		case "week":
			return {
				startDate: startOfWeek(date, { weekStartsOn: 1 }),
				endDate: endOfWeek(date, { weekStartsOn: 1 }),
			};
	}
};

export const isLocked = (date: Date) => {
	const reportLocks = timeReportStore.getState().reportLocks;
	return Boolean(reportLocks.find(r => isSameDay(date, new Date(r.date))));
};

export const getSelectedDateRange = () => {
	const { view, selectedDate, startDate, endDate } =
		timeReportStore.getState();
	const freeDays = betweenDates(startDate, endDate);
	const dateRange = getDates(view, selectedDate);
	const withDayOff: DateRange[] = dateRange.map(date => {
		const dayOff = freeDays.find(d => isSameDay(date, new Date(d.date)));

		return {
			date: date,
			dayOff: Boolean(dayOff),
			dayOffName: dayOff?.name,
		};
	});
	return withDayOff;
};

export const isProjectLocked = (date: Date, projectId: string) => {
	const { projectInfo } = timeReportStore.getState();
	const MAX_TIMESTAMP = 8640000000000000;
	const project = projectInfo.find(ap => ap.id === projectId);

	if (!project) return true;

	const startDate = parseISO(project.startDate);
	const endDate =
		project.endDate === "infinity"
			? new Date(MAX_TIMESTAMP)
			: parseISO(project.endDate);

	return !areIntervalsOverlapping(
		{
			start: startDate,
			end: endDate,
		},
		{
			start: date,
			end: date,
		},
		{
			inclusive: true,
		},
	);
};
