import type { NationalHolidayProducerFunc } from "../public-holiday";

export const newYearsEve: NationalHolidayProducerFunc = year => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(11);
	dateObj.setDate(31);

	return dateObj;
};

export const newYearsDay: NationalHolidayProducerFunc = year => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(0);
	dateObj.setDate(1);

	return dateObj;
};
