import type { AuthStateType } from ".";
import {
	type AuthAction,
	PERFORM_LOGIN_FAILED,
	PERFORM_LOGIN_LOADING,
	PERFORM_LOGIN_SUCCESS,
	PERFORM_LOGOUT_SUCCESS,
	initialAuthState,
} from "./action";

export const parseToken = (token: string) => {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join(""),
	);

	return JSON.parse(jsonPayload);
};

export const authReducer = (
	// biome-ignore lint/style/useDefaultParameterLast: <explanation>
	state = initialAuthState,
	action: AuthAction,
): AuthStateType => {
	switch (action.type) {
		case PERFORM_LOGIN_SUCCESS:
			return {
				...state,
				fetching: false,
				error: undefined,
				user: parseToken(action.payload),
				token: action.payload,
				isAuthenticated: true,
			};
		case PERFORM_LOGIN_FAILED:
			return {
				...state,
				fetching: false,
				error: action.payload,
			};
		case PERFORM_LOGIN_LOADING:
			return {
				...state,
				fetching: true,
			};
		case PERFORM_LOGOUT_SUCCESS:
			return {
				...state,
				fetching: false,
				token: undefined,
				error: undefined,
				isAuthenticated: false,
				user: undefined,
			};
		default:
			return state;
	}
};
