import type { NationalHolidayProducerFunc } from "../public-holiday";

export const midsummerDay: NationalHolidayProducerFunc = year => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(5);
	dateObj.setDate(20);

	const day = dateObj.getDay();

	dateObj.setDate(dateObj.getDate() + 6 - day);

	return dateObj;
};

export const midsummerEve: NationalHolidayProducerFunc = year => {
	const dateObj = new Date(midsummerDay(year).getTime());

	dateObj.setDate(dateObj.getDate() - 1);

	return dateObj;
};
