import { createBrowserRouter } from "react-router-dom";
import { ErrorComponent } from "./error";
import { RequireAuth } from "./components/require-auth";
import { Root } from "./components/root";
import { Customer, Customers } from "./containers/customersv2";
import { Login } from "./containers/login";
import { Report } from "./containers/report";
import { Summary } from "./containers/summary";
import { UserForm } from "./containers/users/form";
import { UserSettings } from "./containers/users/user-settings";
import { Users } from "./containers/users/users";
import { Statistics } from "./containers/summary/statistics";
import { UserDashboard } from "./containers/user-dashboard";
import { User } from "./containers/users/user";
import { ReportV2 } from "./containers/reportV2";
import { CreateOrEdit } from "./containers/customersv2/create-or-edit";
import { Utils } from "./containers/utils/utils";
export const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorComponent />,
		children: [
			{
				element: <RequireAuth />,
				children: [
					{
						index: true,
						element: <UserDashboard />,
					},
					{
						path: "report",
						element: <Report />,
					},
					{
						path: "utils",
						element: <Utils />,
					},
					{
						path: "reportv2",
						element: <ReportV2 />,
					},
					{
						path: "summary",
						element: <Summary />,
					},
					{
						path: "statistics",
						element: <Statistics />,
					},
					{
						path: "settings",
						element: <UserSettings />,
					},
					{
						path: "customersv2",
						children: [
							{
								index: true,
								element: <Customers />,
							},
							{
								path: "create",
								element: <CreateOrEdit />,
							},
							{
								path: ":customerId",
								children: [
									{
										index: true,
										element: <Customer />,
									},
									{
										path: ":page",
										children: [
											{
												index: true,
												element: <Customer />,
											},
											{
												path: ":pageArg",
												element: <Customer />,
											},
										],
									},
								],
							},
						],
					},
					{
						path: "users",
						children: [
							{
								index: true,
								element: <Users />,
							},
							{
								path: "new",
								element: <UserForm />,
							},
							{
								path: ":userId",
								children: [
									{
										index: true,
										element: <User />,
									},
									{
										path: ":page",
										element: <User />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: "about",
				element: <div>About</div>,
			},
		],
	},
	{
		path: "login",
		element: <Login />,
	},
]);
