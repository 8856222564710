import { Flex, NumberInput, Stack } from "@mantine/core";
import type { Project } from "../../generated";
import { useTimeReportStore } from "./store";

import { isSameDay, isWeekend } from "date-fns";

import classes from "./project-row.module.css";

import Duration from "@icholy/duration";
import { type DateRange, isLocked, isProjectLocked } from "./helpers";

import { ProjectRowHeader } from "./project-row/header";
import { TimeInput } from "./project-row/time-input";

type Props = {
	project: Project;
	dates: DateRange[];
	lockedWeekends: boolean;
};

export const ProjectRow = ({ project, dates, lockedWeekends }: Props) => {
	const { reportedTime } = useTimeReportStore();

	const totalTime = reportedTime
		.filter(r => r.project_id === project.id)
		.reduce((acc, curr) => acc + new Duration(curr.time).hours(), 0);

	return (
		<Stack gap={8} py="md">
			<ProjectRowHeader project={project} dates={dates} />
			<Flex className={classes.projectRow}>
				{dates.map(({ date, dayOff }) => {
					const value =
						new Duration(
							reportedTime.find(
								rt =>
									rt.project_id === project.id &&
									isSameDay(date, new Date(rt.date)),
							)?.time,
						)
							.hours()
							.toString() || "";

					const isDisabled =
						(isWeekend(date) && lockedWeekends) ||
						(dayOff && lockedWeekends) ||
						isLocked(date) ||
						isProjectLocked(date, project.id);

					return (
						<TimeInput
							key={date.getTime()}
							value={value}
							date={date}
							disabled={isDisabled}
							project={project}
						/>
					);
				})}
				<div className={classes.total}>
					<NumberInput
						value={totalTime}
						className={classes.date}
						wrapperProps={{
							"data-date": "Total",
						}}
						disabled
						hideControls
						classNames={{ input: classes.totalInput }}
					/>
				</div>
			</Flex>
		</Stack>
	);
};
