import { Button } from "@mantine/core";
import { IconLock, IconLockOpen } from "@tabler/icons-react";
import { useContext } from "react";
import { PeriodSelector } from "../../components/period-selector/period-selector";
import { PeriodSwitcher } from "../../components/period-switcher/period-switcher";
import { TimeReportContext } from "./context";
import classes from "./navigation.module.css";

export const ReportNavigation = () => {
	const timereportContext = useContext(TimeReportContext);

	return timereportContext ? (
		<div className={classes.tableHeader}>
			<PeriodSelector
				value={timereportContext.period}
				onChange={timereportContext.setPeriod}
			/>
			<PeriodSwitcher
				value={timereportContext.selectedDate}
				period={timereportContext.period}
				onChange={timereportContext.setSelectedDate}
			/>
			<Button
				onClick={() =>
					timereportContext.setLockedWeekends(
						!timereportContext.lockedWeekends,
					)
				}
			>
				{timereportContext.lockedWeekends ? (
					<>
						<IconLockOpen /> Unlock weekends
					</>
				) : (
					<>
						<IconLock /> Lock weekends
					</>
				)}
			</Button>
		</div>
	) : null;
};
