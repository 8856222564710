import type { CreateCustomer, Customer } from "../generated";
import { API_BASE_URL } from ".";
import { timeFetch } from ".";

export const getCustomers = () => {
	return timeFetch<Array<Customer>>(`${API_BASE_URL}/api/v1/customers`, {
		method: "GET",
	});
};

export const getCustomer = (id: string) => {
	return timeFetch<Customer>(`${API_BASE_URL}/api/v1/customers/${id}`, {
		method: "GET",
	});
};

export const createCustomer = (project: CreateCustomer) => {
	return timeFetch<Customer>(`${API_BASE_URL}/api/v1/customers`, {
		method: "POST",
		body: JSON.stringify(project),
	});
};

export const updateCustomer = (
	customerId: string,
	customer: CreateCustomer,
) => {
	return timeFetch<Customer>(
		`${API_BASE_URL}/api/v1/customers/${customerId}`,
		{
			method: "PUT",
			body: JSON.stringify(customer),
		},
	);
};

export const deleteCustomer = (objId: string) => {
	return timeFetch<void>(`${API_BASE_URL}/api/v1/customers/${objId}`, {
		method: "DELETE",
	});
};
