import type { AssignedProject, InputProject, Project } from "../generated";
import { API_BASE_URL } from ".";
import { timeFetch } from ".";

export const getProjectsForCustomer = (customerId: string) => {
	return timeFetch<Array<Project>>(
		`${API_BASE_URL}/api/v1/customers/${customerId}/projects`,
		{
			method: "GET",
		},
	);
};

export const getProjects = () => {
	return timeFetch<Array<Project>>(`${API_BASE_URL}/api/v1/projects`, {
		method: "GET",
	});
};

export const getProject = (projectId: string) => {
	return timeFetch<Project>(`${API_BASE_URL}/api/v1/projects/${projectId}`, {
		method: "GET",
	});
};

export const createProject = (project: InputProject) => {
	return timeFetch<Project>(`${API_BASE_URL}/api/v1/projects`, {
		method: "POST",
		body: JSON.stringify(project),
	});
};

export const deleteProject = (objId: string) => {
	return timeFetch<void>(`${API_BASE_URL}/api/v1/projects/${objId}`, {
		method: "DELETE",
	});
};
export const updateProject = (project: InputProject & { id: string }) => {
	return timeFetch<Project>(`${API_BASE_URL}/api/v1/projects/${project.id}`, {
		method: "PUT",
		body: JSON.stringify(project),
	});
};

export const getProjectsFromAssignedProjects = (
	assignedProjects: AssignedProject[],
) => {
	const projectIds = [...new Set(assignedProjects.map(ap => ap.project_id))];

	const promises = projectIds.map(pi => {
		return getProject(pi);
	});

	return Promise.all(promises);
};
