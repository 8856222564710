import type { InputUser, User } from "../generated";
import { API_BASE_URL } from ".";
import { timeFetch } from ".";

export const isUserType = (
	userType?: string | null,
): userType is User["user_type"] => {
	switch (userType) {
		case "internal":
		case "external_consultant":
		case "employee_consultant":
			return true;
		default:
			return false;
	}
};

export const userTypeToReadable = (userType: User["user_type"]) => {
	switch (userType) {
		case "internal":
			return "Internal";
		case "external_consultant":
			return "External Consultant";
		case "employee_consultant":
			return "Employee Consultant";
	}
};

export const getUsers = () => {
	return timeFetch<Array<User>>(`${API_BASE_URL}/api/v1/users`, {
		method: "GET",
	});
};

export const getUser = (userId: string) => {
	return timeFetch<User>(`${API_BASE_URL}/api/v1/users/${userId}`, {
		method: "GET",
	});
};

export const createUser = (project: InputUser) => {
	return timeFetch<User>(`${API_BASE_URL}/api/v1/users`, {
		method: "POST",
		body: JSON.stringify(project),
	});
};

export const updateUser = (
	userId: string,
	project: Omit<InputUser, "password">,
) => {
	return timeFetch<User>(`${API_BASE_URL}/api/v1/users/${userId}`, {
		method: "PUT",
		body: JSON.stringify(project),
	});
};

export const deleteUser = (userId: string) =>
	timeFetch<void>(`${API_BASE_URL}/api/v1/users/${userId}`, {
		method: "DELETE",
	});
