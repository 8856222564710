import { Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { getProjects } from "../../api/projects";
import type { Project } from "../../generated";

interface IProjectSelector {
	onSelect: (projectId: string | null) => void;
	disabled?: string[];
	selected?: string;
	whichProjects?: "all";
}

export const ProjectSelector = ({
	onSelect,
	disabled,
	selected,
	whichProjects = "all",
}: IProjectSelector) => {
	const [projects, setProjects] = useState<Array<Project>>([]);

	useEffect(() => {
		switch (whichProjects) {
			case "all":
				getProjects().then(setProjects);
				break;
			default:
				throw new Error(
					"Invalid whichProjects flag passed to ProjectSelector",
				);
		}
	}, [whichProjects]);

	const list = projects.reduce<
		Array<{
			group: string;
			items: Array<{
				value: string;
				label: string;
				disabled: boolean;
			}>;
		}>
	>((acc, project) => {
		const group = project.customer?.name;

		if (!group) throw new Error("No group on customer");

		let existingGroup = acc.find(i => i.group === group);

		if (!existingGroup) {
			existingGroup = {
				group,
				items: [],
			};
			acc.push(existingGroup);
		}

		existingGroup.items.push({
			value: project.id,
			label: project.name,
			disabled: disabled ? disabled.includes(project.id) : false,
		});

		return acc;
	}, []);

	return (
		<Select
			value={selected || null}
			placeholder="Projekt"
			onChange={onSelect}
			data={list}
		/>
	);
};
