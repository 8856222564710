import Duration from "@icholy/duration";
import { Button, Checkbox, List, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUser } from "@tabler/icons-react";
import { endOfMonth, startOfMonth } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { getProjects } from "../../api/projects";
import { getRegisteredTimeInPeriodForUser } from "../../api/registered-times";
import { getUsers } from "../../api/users";
import type { OutputRegisteredTime, Project, User } from "../../generated";
import { downloadArrayAsCsv } from "../../utils/array-to-csv";

interface IExport {
	date: Date;
	period: "month" | "week";
}

export const Export = ({ date }: IExport) => {
	const [opened, { open, close }] = useDisclosure(false);
	const [userReports, setUserReports] = useState<Array<OutputRegisteredTime>>(
		[],
	);
	const [projects, setProjects] = useState<Array<Project>>([]);
	const [users, setUsers] = useState<Array<User>>([]);
	const [selected, setSelected] = useState<
		Array<{
			userId: string;
			projectId: string;
		}>
	>([]);

	useEffect(() => {
		getUsers().then(setUsers);
		getProjects().then(setProjects);
	}, []);

	useEffect(() => {
		const from = startOfMonth(date);
		const to = endOfMonth(date);
		setUserReports([]);

		getRegisteredTimeInPeriodForUser(from, to).then(setUserReports);
	}, [date]);

	const handleChange = (userId: string, projectId: string) => {
		const item = selected.find(
			sel => sel.userId === userId && sel.projectId === projectId,
		);

		if (item) {
			setSelected(
				selected.filter(
					sel => sel.userId !== userId && sel.projectId !== projectId,
				),
			);
			return;
		}

		setSelected([...selected, { userId, projectId }]);
	};

	const handleDownloadClick = () => {
		const report = selected.map(s => {
			const project = projects.find(p => p.id === s.projectId);
			const user = users.find(u => u.id === s.userId);

			const reports = userReports.filter(
				ur => ur.user_id === s.userId && ur.project_id === s.projectId,
			);

			return [
				user?.name,
				project?.customer?.name,
				project?.name,
				reports.reduce((acc, curr) => {
					const duration = new Duration(curr.time);
					const newAcc = acc + duration.hours();
					return newAcc;
				}, 0),
			];
		}) as Array<Array<string | number>>;

		downloadArrayAsCsv(report);
	};

	return (
		<>
			<Modal opened={opened} onClose={close} title="Export">
				<List icon={<IconUser />}>
					{users.map(user => {
						const projectReports = userReports.filter(
							ur => ur.user_id === user.id,
						);
						if (projectReports.length < 1) return;

						const projs = projectReports
							.map(pr =>
								projects.find(p => p.id === pr.project_id),
							)
							.filter(
								(value, index, array) =>
									array.indexOf(value) === index,
							)
							.filter(p => !!p) as Array<Project>;

						return (
							<Fragment key={user.id}>
								<List.Item>{user.name}</List.Item>
								<List listStyleType="none" withPadding>
									{projs.map(p => {
										return (
											<List.Item key={p.id}>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
													}}
												>
													<Checkbox
														checked={
															!!selected.find(
																s =>
																	s.userId ===
																		user.id &&
																	s.projectId ===
																		p.id,
															)
														}
														onChange={() =>
															handleChange(
																user.id,
																p.id,
															)
														}
													/>{" "}
													{p?.customer?.name} -{" "}
													{p.name}
												</div>
											</List.Item>
										);
									})}
								</List>
							</Fragment>
						);
					})}
				</List>
				<Button onClick={handleDownloadClick}>Export as CSV</Button>
			</Modal>

			<Button onClick={open}>Open modal</Button>
		</>
	);
};
