import { Tooltip } from "@mantine/core";
import {
	eachDayOfInterval,
	format,
	isFirstDayOfMonth,
	isSameDay,
	isSameMonth,
} from "date-fns";
import { useEffect, useState } from "react";
import {
	getReportLocksForPeriod,
	getReportLocksForUserInPeriod,
} from "../../api/report-locks";
import type { OutputReportLock, User } from "../../generated";

const getDateState = (
	date: Date,
	locks: Array<Date>,
): "overdue" | "locked" | "open" => {
	// Is current month
	if (isSameMonth(new Date(), date)) {
		if (locks.find(ld => isSameDay(ld, date))) return "locked";
		return "open";
	}

	if (locks.find(ld => isSameDay(ld, date))) return "locked";
	return "overdue";
};

interface IReportDays {
	dates: Array<Date>;
	locks: Array<OutputReportLock>;
}

const ReportDays = ({ dates, locks }: IReportDays) => {
	const lockedDates = locks.map(lock => new Date(lock.date));

	const stateToColor = {
		overdue: "var(--mantine-color-red-4)",
		locked: "var(--mantine-color-green-4)",
		open: "var(--mantine-color-yellow-4)",
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
			}}
		>
			{dates.map(date => {
				const dateState = getDateState(date, lockedDates);

				return (
					<Tooltip
						key={date.getTime()}
						label={`${format(date, "yyyy-MM-dd")} ${dateState}`}
					>
						<div
							style={{
								width: `${100 / dates.length}%`,
								height: "20px",
								background: stateToColor[dateState],
								borderRight:
									"1px solid var(--mantine-color-dark-4)",
								borderLeft: isFirstDayOfMonth(date)
									? "2px solid var(--mantine-color-dark-8)"
									: "none",
							}}
						/>
					</Tooltip>
				);
			})}
		</div>
	);
};

interface IReportMonthHeader {
	dates: Array<Date>;
	locks: Array<OutputReportLock>;
}

const ReportMonthHeader = ({ dates, locks }: IReportMonthHeader) => {
	const datesPerMonth = dates.reduce<{
		[key: string]: Array<Date>;
	}>((acc, curr) => {
		const month = format(curr, "MMM");
		if (!acc[month]) acc[month] = [];
		acc[month].push(curr);
		return acc;
	}, {});

	const totalDays = Object.entries(datesPerMonth).reduce<number>(
		(acc, [, days]) => {
			return acc + days.length;
		},
		0,
	);

	const percentagePerMonth = Object.entries(datesPerMonth).reduce<{
		[key: string]: number;
	}>((acc, [month, days]) => {
		acc[month] = (days.length / totalDays) * 100;
		return acc;
	}, {});

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
			}}
		>
			{Object.entries(percentagePerMonth).map(([month, width]) => {
				return (
					<div
						key={month}
						style={{
							width: `${width}%`,
						}}
					>
						<div>{month}</div>
						<div>
							<ReportDays
								dates={datesPerMonth[month]}
								locks={locks}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};

interface IReportHistory {
	from: Date;
	to: Date;
	user?: User;
}

export const ReportLineOverview = ({ from, to, user }: IReportHistory) => {
	const [reportLocks, setReportLocks] = useState<Array<OutputReportLock>>([]);

	useEffect(() => {
		if (user)
			getReportLocksForUserInPeriod(user.id, from, to).then(
				setReportLocks,
			);
		else getReportLocksForPeriod(from, to).then(setReportLocks);
	}, [from, to, user]);

	const datesInRange = eachDayOfInterval({ start: from, end: to });

	return <ReportMonthHeader dates={datesInRange} locks={reportLocks} />;
};
