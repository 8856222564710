import { useEffect, useState } from "react";
import type {
	Project as APIProject,
	Contact,
	InputProject,
} from "../../../generated";
import {
	Button,
	Container,
	Divider,
	Flex,
	Group,
	Input,
	Select,
	SimpleGrid,
} from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconArrowLeft, IconEdit } from "@tabler/icons-react";
import { add as addNotification } from "../../../store/notifications";
import {
	createProject,
	getProject,
	updateProject,
} from "../../../api/projects";
import { getContactsByCustomerId } from "../../../api/contacts";

type IProject = {
	projectId?: string;
};

export const Project = ({ projectId }: IProject) => {
	const navigate = useNavigate();
	const [project, setProject] = useState<APIProject>();
	const { customerId, page } = useParams();
	const [editing, setEditing] = useState<boolean>(!projectId);
	const [contacts, setContacts] = useState<Contact[]>();
	const [editValues, setEditValues] = useState<InputProject & { id: string }>(
		{
			id: projectId,
			customerId: customerId || "error",
			name: "",
		},
	);

	useEffect(() => {
		if (!projectId) return;

		getProject(projectId).then(setProject);
	}, [projectId]);

	useEffect(() => {
		if (!customerId) return;
		getContactsByCustomerId(customerId).then(setContacts);
	}, [customerId]);

	useEffect(() => {
		if (!project) return;

		setEditValues({
			...editValues,
			name: project.name,
			note: project.note,
			contactId: project.contactId,
		});
	}, [project]);

	const handleSave = () => {
		if (projectId) {
			updateProject(editValues)
				.then(setProject)
				.finally(() => {
					setEditing(false);
					addNotification({
						title: "Successfully updated contact",
						message: `Updated ${editValues.name}`,
						lifetime: 3000,
					});
				});
		} else {
			createProject(editValues)
				.then(p => {
					setProject(p);
					navigate(`/customersv2/${customerId}/${page}/${p.id}`);
				})
				.finally(() => {
					setEditing(false);
					addNotification({
						title: "Successfully created contact",
						message: `Created ${editValues.name}`,
						lifetime: 3000,
					});
				});
		}
	};

	return (
		<Container>
			<Flex justify="space-between">
				<Button
					size="compact-md"
					component={Link}
					to={`/customersv2/${customerId}/${page}`}
					variant="light"
					leftSection={<IconArrowLeft size={14} />}
				>
					Project list
				</Button>
				{editing ? (
					<Group>
						{projectId && (
							<Button
								size="compact-md"
								onClick={() => setEditing(false)}
								variant="light"
								color="red"
								leftSection={<IconEdit size={14} />}
							>
								Cancel
							</Button>
						)}
						<Button
							size="compact-md"
							onClick={handleSave}
							variant="light"
							color="green"
							leftSection={<IconEdit size={14} />}
						>
							Save
						</Button>
					</Group>
				) : (
					<Button
						size="compact-md"
						onClick={() => setEditing(true)}
						variant="light"
						leftSection={<IconEdit size={14} />}
					>
						Edit
					</Button>
				)}
			</Flex>
			<Divider my="md" />
			<SimpleGrid cols={2}>
				<SimpleGrid cols={2}>
					<span>Name</span>
					{editing || !project ? (
						<Input
							value={editValues.name}
							onChange={ev =>
								setEditValues({
									...editValues,
									name: ev.target.value,
								})
							}
						/>
					) : (
						<span>{project.name}</span>
					)}
					<span>Note</span>
					{editing || !project ? (
						<Input
							value={editValues.note}
							onChange={ev =>
								setEditValues({
									...editValues,
									note: ev.target.value,
								})
							}
						/>
					) : (
						<span>{project.note}</span>
					)}
					<span>Contact</span>
					{(editing || !project) && contacts ? (
						<Select
							placeholder="Pick a contact"
							value={editValues.contactId}
							onChange={val => {
								if (!val) return;

								setEditValues({
									...editValues,
									contactId: val,
								});
							}}
							data={contacts.map(c => ({
								value: c.id,
								label: c.name || "No name",
							}))}
						/>
					) : (
						<span>
							{
								contacts?.find(c => c.id === project?.contactId)
									?.name
							}
						</span>
					)}
				</SimpleGrid>
			</SimpleGrid>
		</Container>
	);
};
