import { useRouteError } from "react-router-dom";

export const ErrorComponent = () => {
	const error = useRouteError();

	if (error instanceof Error) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre style={{ color: "red" }}>{error.message}</pre>
			</div>
		);
	}

	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre style={{ color: "red" }}>Wops</pre>
		</div>
	);
};
