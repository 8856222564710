import { AreaChart, type AreaChartSeries } from "@mantine/charts";
import type { OutputRegisteredTime, Project } from "../../generated";
import { useEffect, useState } from "react";
import {
	eachMonthOfInterval,
	endOfMonth,
	format,
	startOfMonth,
	sub,
} from "date-fns";
import { getRegisteredTimeInPeriodForCustomer } from "../../api/registered-times";
import Duration from "@icholy/duration";
import { getProjectsForCustomer } from "../../api/projects";

type IInfo = {
	customerId: string;
};

type Data = {
	[key: string]: number | string;
};

function djb2(str: string) {
	let hash = 5381;
	for (let i = 0; i < str.length; i++) {
		hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
	}
	return hash;
}

function hashStringToColor(str: string) {
	const hash = djb2(str);
	const r = (hash & 0xff0000) >> 16;
	const g = (hash & 0x00ff00) >> 8;
	const b = hash & 0x0000ff;
	return `#${`0${r.toString(16)}`.substr(-2)}${`0${g.toString(16)}`.substr(
		-2,
	)}${`0${b.toString(16)}`.substr(-2)}`;
}

export const Info = ({ customerId }: IInfo) => {
	const [registeredTimes, setRegisteredTimes] = useState<
		OutputRegisteredTime[]
	>([]);
	const [data, setData] = useState<Data[]>([]);
	const [projects, setProjects] = useState<Project[]>([]);
	const [series, setSeries] = useState<AreaChartSeries[]>([]);

	useEffect(() => {
		getRegisteredTimeInPeriodForCustomer(
			customerId,
			sub(startOfMonth(new Date()), { months: 6 }),
			endOfMonth(new Date()),
		).then(setRegisteredTimes);

		getProjectsForCustomer(customerId).then(setProjects);
	}, [customerId]);

	useEffect(() => {
		setSeries([
			...projects.map(p => ({
				name: p.id,
				color: hashStringToColor(p.id),
				label: p.name,
			})),
		]);
	}, [projects]);

	useEffect(() => {
		const pIds = projects.reduce<{ [key: string]: number }>((acc, curr) => {
			acc[curr.id] = 0;

			return acc;
		}, {});

		const initialValue = eachMonthOfInterval({
			start: sub(startOfMonth(new Date()), { months: 6 }),
			end: endOfMonth(new Date()),
		}).map(d => ({ date: format(d, "yyyy-MM"), ...pIds }));

		const data = registeredTimes.reduce<Data[]>((acc, curr) => {
			const month = format(curr.date, "yyyy-MM");

			// Make sure we have a object for this month in acc
			let mObj = acc.find(m => m.date === month);
			if (!mObj) {
				mObj = { date: month };
				acc.push(mObj);
			}

			// Add the registered time to data
			if (!mObj[curr.project_id]) mObj[curr.project_id] = 0;
			const duration = new Duration(curr.time);
			mObj[curr.project_id] = +mObj[curr.project_id] + duration.hours();

			return acc;
		}, initialValue);

		setData(data);
	}, [registeredTimes, projects]);

	return (
		<AreaChart
			h={300}
			data={data}
			dataKey="date"
			withLegend
			series={series}
		/>
	);
};
