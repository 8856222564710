import { Button, NumberInput, Table, Text } from "@mantine/core";
import { format, isSameDay, isWeekend } from "date-fns";
import { isEqual } from "date-fns";
import { useContext } from "react";
import type { ReportedTime } from "../../../types";
import type { Project } from "../../generated";
import { TimeReportContext } from "./context";
import { NumberCell } from "./number-cell";
import classes from "./project-row.module.css";

type Props = {
	project: Project;
};

export const ProjectRow = ({ project }: Props) => {
	const timereportContext = useContext(TimeReportContext);
	if (!timereportContext) return null;

	const currentProject = timereportContext.reportedTimes.filter(
		t => t.projectId === project.id,
	);

	const totalTime =
		currentProject.length > 0
			? timereportContext.visibleDates
					.map(d => currentProject.find(p => isEqual(p.date, d)))
					.filter((x): x is ReportedTime => !!x)
					.reduce((acc, curr) => acc + curr.time, 0)
			: 0;

	const handleFullTimeAssignment = () => {
		const fullTime: ReportedTime[] = [];
		for (const d of timereportContext.visibleDates) {
			if (timereportContext.lockedWeekends && isWeekend(d)) {
				continue;
			}
			fullTime.push({ projectId: project.id, date: d, time: 8 });
		}

		const updatedProjects = timereportContext.reportedTimes
			.filter(r => r.projectId !== project.id)
			.concat([...fullTime]);

		timereportContext.setReportedTimes(updatedProjects);
	};

	const handleTimeCellChange = (
		date: Date,
		projectId: string,
		time?: number,
	) => {
		const existingTime = timereportContext.reportedTimes.find(
			time =>
				time.projectId === projectId &&
				time.date.getTime() === date.getTime(),
		);

		if (existingTime) {
			if (time == null) {
				// Delete maybe?
			} else {
				timereportContext.setReportedTimes(
					timereportContext.reportedTimes.map(rt => {
						if (
							rt.projectId === projectId &&
							rt.date.getTime() === date.getTime()
						) {
							return {
								...rt,
								time: time,
							};
						}
						return rt;
					}),
				);
			}
		} else {
			if (time == null) {
				// Hmmm
			} else {
				timereportContext.setReportedTimes([
					...timereportContext.reportedTimes,
					{
						time,
						projectId,
						date,
					},
				]);
			}
		}
	};

	return (
		<Table.Tr key={project.id}>
			<Table.Td className={classes.firstColumn}>
				<Text>{project.name}</Text>
				<Text fz="sm" c="dimmed">
					{project.customer?.name}
				</Text>
			</Table.Td>
			{timereportContext.visibleDates.map((d: Date) => {
				if (!project.id) return null;

				const reportedTime = timereportContext.reportedTimes.find(
					rt => rt.projectId === project.id && isSameDay(d, rt.date),
				);

				return (
					<NumberCell
						key={format(d, "yMd")}
						date={d}
						value={reportedTime?.time || ""}
						projectId={project.id}
						lockedWeekends={timereportContext.lockedWeekends}
						onChange={handleTimeCellChange}
					/>
				);
			})}
			<Table.Td>
				<NumberInput
					variant="unstyled"
					value={totalTime}
					hideControls
					classNames={{ input: classes.input }}
					style={{ padding: "0 10px" }}
					disabled
				/>
			</Table.Td>
			<Table.Td>
				<Button
					value={project.id}
					style={{ marginLeft: "8px" }}
					onClick={handleFullTimeAssignment}
				>
					Full time
				</Button>
			</Table.Td>
		</Table.Tr>
	);
};
