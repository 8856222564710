import { Container } from "@mantine/core";
import { API_BASE_URL } from "../../api";

export const Utils = () => {
	return (
		<Container fluid p="xl">
			<div>Timerep CLI</div>
			<div>
				<a href={`${API_BASE_URL}/api/v1/files/mac/timerep`}>Mac</a>
			</div>
			<div>
				<a href={`${API_BASE_URL}/api/v1/files/win/timerep`}>Windows</a>
			</div>
			<div>
				<a href={`${API_BASE_URL}/api/v1/files/linux/timerep`}>Linux</a>
			</div>
		</Container>
	);
};
