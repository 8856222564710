import {
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	startOfMonth,
	startOfWeek,
} from "date-fns";
import type { Period } from "../../../types";

export const getDates = (period: Period, date: Date) => {
	if (period === "month") {
		return eachDayOfInterval({
			start: startOfMonth(date),
			end: endOfMonth(date),
		});
	}
	return eachDayOfInterval({
		start: startOfWeek(date, { weekStartsOn: 1 }),
		end: endOfWeek(date, { weekStartsOn: 1 }),
	});
};
