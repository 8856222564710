import { Box, SegmentedControl } from "@mantine/core";
import { setView, useTimeReportStore } from "./store";

export const ViewSwitcher = () => {
	const { view } = useTimeReportStore();

	return (
		<SegmentedControl
			visibleFrom="md"
			value={view}
			onChange={setView}
			data={[
				{
					value: "week",
					label: <Box>Vecka</Box>,
				},
				{
					value: "month",
					label: <Box>Månad</Box>,
				},
			]}
		/>
	);
};
