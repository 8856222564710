import { useEffect, useState } from "react";
import type { CreateCustomer } from "../../generated";
import { Button, Container, Group, Stack, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import {
	createCustomer,
	getCustomer,
	updateCustomer,
} from "../../api/customers";
import { add as addNotification } from "../../store/notifications";

type ICreateOrEdit = {
	customerId?: string;
};

export const CreateOrEdit = ({ customerId }: ICreateOrEdit) => {
	const navigate = useNavigate();
	const [customer, setCustomer] = useState<CreateCustomer>({
		name: "",
		vat_number: "",
		billing_address: "",
	});

	useEffect(() => {
		if (!customerId) return;

		getCustomer(customerId).then(setCustomer);
	}, [customerId]);

	const saveCustomer = () => {
		if (customerId) {
			updateCustomer(customerId, customer).then(newCustomer => {
				addNotification({
					title: "Successfully created customer",
					message: `Created customer ${customer.name}`,
				});
				navigate(`/customersv2/${newCustomer.id}`);
			});
			return;
		}

		createCustomer(customer).then(newCustomer => {
			addNotification({
				title: "Successfully created customer",
				message: `Created customer ${customer.name}`,
			});
			navigate(`/customersv2/${newCustomer.id}`);
		});
	};

	return (
		<Container>
			<Stack>
				<TextInput
					value={customer.name}
					label="Name"
					placeholder="Virki AB"
					description="Customer name"
					onChange={e =>
						setCustomer({ ...customer, name: e.target.value })
					}
				/>
				<TextInput
					value={customer.vat_number}
					label="VAT"
					placeholder="123456789012"
					description="VAT number"
					onChange={e =>
						setCustomer({ ...customer, vat_number: e.target.value })
					}
				/>
				<TextInput
					value={customer.billing_address}
					label="Address"
					placeholder="Strandbergsgatan 61, 112 51 Stockholm"
					description="Customer billing address"
					onChange={e =>
						setCustomer({
							...customer,
							billing_address: e.target.value,
						})
					}
				/>

				<Group justify="space-between">
					{!customerId && (
						<Button
							color="red"
							onClick={() => navigate("/customersv2")}
						>
							Cancel
						</Button>
					)}
					<Button onClick={saveCustomer}>Save</Button>
				</Group>
			</Stack>
		</Container>
	);
};
