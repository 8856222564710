import { useEffect, useLayoutEffect } from "react";
import clsx from "clsx";
import {
	fetchReportData,
	initializeState,
	toggleWeekendsLocked,
	useTimeReportStore,
} from "./store";
import { ProjectRow } from "./project-row";
import { Container, Paper, Title } from "@mantine/core";
import { DateHeader } from "./date-header";
import { useCurrentViewport } from "../../hooks/use-current-viewport";
import { getSelectedDateRange } from "./helpers";
import { useViewportSize } from "@mantine/hooks";

import classes from "./report.module.css";

import { ReportFooter } from "./report-footer";
import { SummaryRow } from "./summary-row";

export const ReportV2 = () => {
	const {
		selectedDate,
		startDate,
		endDate,
		projectInfo,
		projects,
		view,
		weekendsLocked,
	} = useTimeReportStore();
	const { smallScreen } = useCurrentViewport();
	const { width } = useViewportSize();

	const dates = getSelectedDateRange();
	useLayoutEffect(() => {
		initializeState(smallScreen ? "week" : "month", selectedDate);
	}, [width]);

	useEffect(() => {
		fetchReportData(startDate, endDate);
	}, []);

	return (
		<Container fluid mt="md">
			<div
				className={clsx(
					classes.wrapper,
					view === "week" && classes.weekView,
				)}
			>
				<Paper shadow="xs" p="xs" bg="var(--mantine-color-dark-8)">
					<DateHeader dates={dates} />
					{projectInfo.length ? (
						<>
							{projects.map(p => (
								<ProjectRow
									key={p.id}
									project={p}
									dates={dates}
									lockedWeekends={weekendsLocked}
								/>
							))}
							<Title order={6}>Total</Title>
							<SummaryRow dates={dates} />
						</>
					) : (
						<Title order={3}>
							No assigned projects during selected date range
						</Title>
					)}
				</Paper>
			</div>
			<ReportFooter
				dates={dates}
				toggleLockedWeekends={toggleWeekendsLocked}
			/>
		</Container>
	);
};
