import { format, intervalToDuration } from "date-fns";
import type { InputRegisteredTime, OutputRegisteredTime } from "../generated";
import { API_BASE_URL } from ".";
import { timeFetch } from ".";

export const registerTime = (time: InputRegisteredTime) => {
	return timeFetch<OutputRegisteredTime>(
		`${API_BASE_URL}/api/v1/registered_times/${time.project}`,
		{
			method: "POST",
			body: JSON.stringify(time),
		},
	);
};

export const getRegisteredTimeInPeriodForUser = (
	startDate: Date,
	endDate: Date,
	userId?: string,
) => {
	const url = userId
		? `${API_BASE_URL}/api/v1/users/${userId}/registered_times`
		: `${API_BASE_URL}/api/v1/me/registered_times`;

	return timeFetch<Array<OutputRegisteredTime>>(
		`${url}?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{ method: "GET" },
	);
};

export const getRegisteredTimeInPeriod = (startDate: Date, endDate: Date) => {
	return timeFetch<Array<OutputRegisteredTime>>(
		`${API_BASE_URL}/api/v1/registered_times?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{ method: "GET" },
	);
};

export const getRegisteredTimeInPeriodForCustomer = (
	customerId: string,
	startDate: Date,
	endDate: Date,
) =>
	timeFetch<Array<OutputRegisteredTime>>(
		`${API_BASE_URL}/api/v1/customers/${customerId}/registered_times?from=${format(
			startDate,
			"yyyy-MM-dd",
		)}&to=${format(endDate, "yyyy-MM-dd")}`,
		{ method: "GET" },
	);
export const batchRegisterTime = async (times: InputRegisteredTime[]) =>
	Promise.all(times.map(time => registerTime(time)));
