import { NumberInput, Table } from "@mantine/core";
import { isWeekend } from "date-fns";
import { useContext } from "react";
import { TimeReportContext } from "./context";

import classes from "./number-cell.module.css";

interface INumberCell {
	date: Date;
	value: number | "";
	projectId: string;
	lockedWeekends: boolean;
	onChange: (date: Date, projectId: string, time?: number) => void;
}

export const NumberCell = ({
	date,
	value,
	projectId,
	lockedWeekends,
	onChange,
}: INumberCell) => {
	const timereportContext = useContext(TimeReportContext);

	if (!timereportContext) return null;

	const { dateIsLocked, isProjectLocked } = timereportContext;

	const handleChange = (date: Date, value: number | string) => {
		const val = +value;
		if (Number.isNaN(val)) return;
		onChange(date, projectId, val);
	};

	return (
		<Table.Td>
			<NumberInput
				variant="unstyled"
				disabled={
					dateIsLocked(date)
						? true
						: (isWeekend(date) && lockedWeekends) ||
							isProjectLocked(date, projectId)
				}
				value={value}
				placeholder="0"
				min={0}
				max={24}
				allowDecimal={false}
				hideControls
				onChange={value => handleChange(date, value)}
				classNames={{ input: classes.input }}
			/>
		</Table.Td>
	);
};
