import Duration from "@icholy/duration";
import type { BaseSyntheticEvent } from "react";
import party, { Color } from "party-js";
import {
	areIntervalsOverlapping,
	format,
	isEqual,
	isSameDay,
	parseISO,
} from "date-fns";
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useEffect,
	useState,
} from "react";
import type { Period, ReportedTime } from "../../../types";
import { getMyAssignedProjects } from "../../api/me";
import { getProjectsFromAssignedProjects } from "../../api/projects";
import {
	getRegisteredTimeInPeriodForUser,
	registerTime,
} from "../../api/registered-times";
import {
	createReportLocks,
	getReportLocksForPeriod,
} from "../../api/report-locks";
import type {
	AssignedProject,
	OutputRegisteredTime,
	OutputReportLock,
	Project,
} from "../../generated";
import { getDates } from "./helpers";
import { add as addNotification } from "../../store/notifications";
import { IconCheck } from "@tabler/icons-react";
import { rem } from "@mantine/core";
import { useCurrentViewport } from "../../hooks/use-current-viewport";

type TimeReportContextType = {
	projects: Project[];
	setProjects: Dispatch<SetStateAction<Project[]>>;
	selectedDate: Date;
	setSelectedDate: Dispatch<SetStateAction<Date>>;
	period: Period;
	loading: boolean;
	reportLocks: OutputReportLock[];
	setPeriod: Dispatch<SetStateAction<Period>>;
	lockedWeekends: boolean;
	setLockedWeekends: Dispatch<SetStateAction<boolean>>;
	reportedTimes: ReportedTime[];
	setReportedTimes: Dispatch<SetStateAction<ReportedTime[]>>;
	visibleDates: Date[];
	saveCurrentPeriod: (e: BaseSyntheticEvent) => void;
	lockCurrentPeriod: () => void;
	dateIsLocked: (date: Date) => boolean;
	isProjectLocked: (date: Date, projectId: string) => boolean;
	hasUnlockedDays: boolean;
};

export const TimeReportContext = createContext<TimeReportContextType | null>(
	null,
);

interface ITimeReportProvider {
	children: React.ReactNode;
}

const mergeOutputRegisteredTimesToReportedTimes =
	(reportedTimes: Array<ReportedTime>) =>
	(returnedTimes: Array<OutputRegisteredTime>) =>
		returnedTimes.reduce<Array<ReportedTime>>((acc, curr) => {
			const date = parseISO(curr.date);
			const duration = new Duration(curr.time);

			// Does it already exist in accumulator?
			const existingReportIdx = acc.findIndex(
				tr =>
					tr.projectId === curr.project_id &&
					tr.date.getTime() === date.getTime(),
			);

			// If yes, update that instead.
			if (existingReportIdx > -1) {
				acc[existingReportIdx] = {
					date: date,
					projectId: curr.project_id,
					time: duration.hours(),
				};

				return acc;
			}

			acc.push({
				date: date,
				projectId: curr.project_id,
				time: duration.hours(),
			});

			return acc;
		}, reportedTimes);

export const TimeReportProvider = ({ children }: ITimeReportProvider) => {
	const { smallScreen, largeScreen } = useCurrentViewport();

	const [assignedProjects, setAssignedProjects] = useState<AssignedProject[]>(
		[],
	);
	const [projects, setProjects] = useState<Project[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [lockedWeekends, setLockedWeekends] = useState(true);
	const [reportedTimes, setReportedTimes] = useState<ReportedTime[]>([]);
	const [reportLocks, setReportLocks] = useState<OutputReportLock[]>([]);
	const [period, setPeriod] = useState<Period>("month");
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [visibleDates, setVisibleDates] = useState<Date[]>([]);

	useEffect(() => {
		getProjectsFromAssignedProjects(assignedProjects).then(setProjects);
	}, [assignedProjects]);

	useEffect(() => {
		if (smallScreen && period !== "week") {
			setPeriod("week");
		}
		if (largeScreen && period !== "month") {
			setPeriod("month");
		}
	}, [smallScreen, largeScreen, period]);

	useEffect(() => {
		setVisibleDates(getDates(period, selectedDate));
	}, [selectedDate, period]);

	useEffect(() => {
		if (visibleDates.length < 1) return;

		setLoading(true);
		const sortedDates = visibleDates.sort(
			(a, b) => a.getTime() - b.getTime(),
		);

		const firstDate = sortedDates[0];
		const lastDate = sortedDates[sortedDates.length - 1];

		getMyAssignedProjects(
			visibleDates[0],
			visibleDates[visibleDates.length - 1],
		).then(setAssignedProjects);

		getRegisteredTimeInPeriodForUser(firstDate, lastDate)
			.then(mergeOutputRegisteredTimesToReportedTimes(reportedTimes))
			.then(setReportedTimes)
			.finally(() => setLoading(false));

		getReportLocksForPeriod(firstDate, lastDate)
			.then(setReportLocks)
			.finally(() => setLoading(false));
	}, [visibleDates]);

	const lockCurrentPeriod = () => {
		const datesToLock = visibleDates
			.filter(d => !dateIsLocked(d))
			.map(d => format(d, "yyyy-MM-dd"));

		if (datesToLock.length < 1) return;

		createReportLocks(datesToLock)
			.then(newLocks => {
				setReportLocks([...reportLocks, ...newLocks]);
			})
			.then(async () => {
				const daysStr =
					datesToLock.length > 1
						? `${format(datesToLock[0], "MMM dd")} to ${format(
								datesToLock[datesToLock.length - 1],
								"MMM dd",
							)}`
						: format(datesToLock[0], "MMM dd");

				addNotification({
					title: "Locked period",
					message: `Locked ${daysStr}`,
					lifetime: 3000,
				});

				/* UUUUUUGLY */
				const ths = document.querySelectorAll("th");
				if (!ths) return;

				for (const th of ths) {
					party.confetti(th, {
						shapes: "star",
						color: Color.fromHex("#ffff00"),
						count: party.variation.range(7, 4),
					});

					await new Promise(resolve => setTimeout(resolve, 100));
				}
			});
	};

	const saveCurrentPeriod = (e: BaseSyntheticEvent) => {
		const promises = reportedTimes
			.filter(d => !!visibleDates.find(p => isEqual(p, d.date)))
			.map(r =>
				registerTime({
					date: format(r.date, "yyyy-MM-dd"),
					project: r.projectId,
					time: `${r.time}h`,
				})
					.then(report =>
						mergeOutputRegisteredTimesToReportedTimes(
							reportedTimes,
						)([report]),
					)
					.then(setReportedTimes),
			);

		Promise.all(promises)
			.then(() =>
				addNotification({
					title: "Saved current period",
					message: "Successfully saved period",
					color: "green",
					icon: (
						<IconCheck
							style={{ width: rem(20), height: rem(20) }}
						/>
					),
					lifetime: 3000,
				}),
			)
			.finally(() =>
				party.confetti(e.target, {
					count: party.variation.range(7, 4),
				}),
			);
	};

	const dateIsLocked = (date: Date): boolean => {
		return !!reportLocks.find(l => {
			return isSameDay(date, new Date(l.date));
		});
	};

	const isProjectLocked = (date: Date, projectId: string) => {
		const MAX_TIMESTAMP = 8640000000000000;
		const aps = assignedProjects.filter(ap => ap.project_id === projectId);

		if (!aps) return true;

		return !aps.find(ap => {
			const startDate = parseISO(ap.start_date);
			const endDate =
				ap.end_date === "infinity"
					? new Date(MAX_TIMESTAMP)
					: parseISO(ap.end_date);

			return areIntervalsOverlapping(
				{
					start: startDate,
					end: endDate,
				},
				{
					start: date,
					end: date,
				},
				{ inclusive: true },
			);
		});
	};

	return (
		<TimeReportContext.Provider
			value={{
				projects,
				setProjects,
				selectedDate,
				setSelectedDate,
				period,
				setPeriod,
				lockedWeekends,
				setLockedWeekends,
				reportedTimes,
				setReportedTimes,
				reportLocks,
				loading,
				visibleDates,
				lockCurrentPeriod,
				saveCurrentPeriod,
				dateIsLocked,
				isProjectLocked,
				hasUnlockedDays:
					visibleDates.filter(d => !dateIsLocked(d)).length > 0,
			}}
		>
			{children}
		</TimeReportContext.Provider>
	);
};
