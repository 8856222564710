import { Button, Flex, Paper, Stack } from "@mantine/core";
import { IconCheck, IconLock } from "@tabler/icons-react";
import clsx from "clsx";
import {
	createReportLocks,
	getReportLocksForPeriod,
} from "../../api/report-locks";
import { add as addNotification } from "../../store/notifications";
import { isLocked, type DateRange } from "./helpers";
import { format, getMonth } from "date-fns";
import { setReportLocks, useTimeReportStore } from "./store";
import party, { Color } from "party-js";

type Props = {
	dates: DateRange[];
	toggleLockedWeekends: () => void;
};

import classes from "./report.module.css";

export const ReportFooter = ({ dates, toggleLockedWeekends }: Props) => {
	const { startDate, endDate, view, weekendsLocked } = useTimeReportStore();
	const isOverlapping = getMonth(startDate) !== getMonth(endDate);

	const datesToLockByMonth = (month: number) =>
		dates.filter(d => !isLocked(d.date) && getMonth(d.date) === month);

	const startDates = datesToLockByMonth(getMonth(startDate));
	const endDates = datesToLockByMonth(getMonth(endDate));

	const lockDatesByMonth = (monthToLock: number) => {
		const datesToLock = datesToLockByMonth(monthToLock).map(d =>
			format(d.date, "yyyy-MM-dd"),
		);

		if (datesToLock.length < 1) return;

		createReportLocks(datesToLock)
			.then(() => {
				getReportLocksForPeriod(startDate, endDate).then(
					setReportLocks,
				);
			})
			.then(async () => {
				const daysStr =
					datesToLock.length > 1
						? `${format(datesToLock[0], "MMM dd")} to ${format(
								datesToLock[datesToLock.length - 1],
								"MMM dd",
							)}`
						: format(datesToLock[0], "MMM dd");

				addNotification({
					title: "Locked period",
					message: `Locked ${daysStr}`,
					lifetime: 3000,
				});

				/* UUUUUUGLY */
				const datapartys = document.querySelectorAll("[data-party]");
				if (!datapartys) return;

				for (const dataparty of datapartys) {
					if (dataparty instanceof HTMLElement) {
						party.confetti(dataparty, {
							shapes: "star",
							color: Color.fromHex("#ffff00"),
							count: party.variation.range(7, 4),
						});

						await new Promise(resolve => setTimeout(resolve, 100));
					}
				}
			});
	};

	return (
		<Stack
			style={{ position: "sticky", bottom: "var(--mantine-spacing-md)" }}
			className={clsx(view === "week" && classes.weekView)}
		>
			<Paper shadow="sm" p="xs" mt="sm" bg="var(--mantine-color-dark-8)">
				<Flex gap="md" justify="end">
					<Button onClick={toggleLockedWeekends}>
						{weekendsLocked ? "Unlock weekends" : "Lock weekends"}
					</Button>
					{!isOverlapping ? (
						<Button
							leftSection={
								startDates.length ? (
									<IconLock size={22} />
								) : (
									<IconCheck size={22} />
								)
							}
							color={startDates.length ? "orange" : "blue"}
							disabled={startDates.length < 1}
							onClick={() =>
								lockDatesByMonth(getMonth(startDate))
							}
						>
							Lock
						</Button>
					) : (
						<>
							<Button
								color={startDates.length ? "orange" : "blue"}
								disabled={startDates.length < 1}
								leftSection={
									startDates.length ? (
										<IconLock size={22} />
									) : (
										<IconCheck size={22} />
									)
								}
								onClick={() =>
									lockDatesByMonth(getMonth(startDate))
								}
							>
								Lock {format(startDate, "MMM")}
							</Button>
							<Button
								color={endDates.length ? "orange" : "blue"}
								disabled={endDates.length < 1}
								leftSection={
									endDates.length ? (
										<IconLock size={22} />
									) : (
										<IconCheck size={22} />
									)
								}
								onClick={() =>
									lockDatesByMonth(getMonth(endDate))
								}
							>
								Lock {format(endDate, "MMM")}
							</Button>
						</>
					)}
				</Flex>
			</Paper>
		</Stack>
	);
};
