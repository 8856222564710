import { ActionIcon, AppShell } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { Outlet, useLocation } from "react-router-dom";
import { LeftMenu } from "./left-menu";
import { NotificationsManager } from "./notifications-manager";
import { useDisclosure } from "@mantine/hooks";
import { IconMenu2, IconX } from "@tabler/icons-react";
import { useEffect } from "react";
import { Suspense } from "react";
import { Loading } from "./loading";

export const Root = () => {
	const [opened, { toggle, close }] = useDisclosure();
	const location = useLocation();

	useEffect(() => {
		if (opened) close();
	}, [location]);

	return (
		<>
			<NotificationsManager />
			<ActionIcon
				hiddenFrom="sm"
				variant="filled"
				onClick={toggle}
				size="xl"
				style={{
					position: "fixed",
					zIndex: 1000,
					bottom: "24px",
					right: "24px",
				}}
			>
				{opened ? <IconX /> : <IconMenu2 />}
			</ActionIcon>
			<AppShell
				withBorder={false}
				navbar={{
					width: 300,
					breakpoint: "sm",
					collapsed: { mobile: !opened },
				}}
			>
				<AppShell.Navbar bg="transparent">
					<LeftMenu />
				</AppShell.Navbar>
				<AppShell.Main>
					<Suspense fallback={<Loading />}>
						<Outlet />
					</Suspense>
				</AppShell.Main>
			</AppShell>
		</>
	);
};
