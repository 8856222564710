import type { NationalHolidayProducerFunc } from "../public-holiday";

export const halloween: NationalHolidayProducerFunc = (year: number) => {
	const dateObj = new Date(`${year}`);

	dateObj.setMonth(9);
	dateObj.setDate(31);

	const first = dateObj.getDate() - dateObj.getDay() + 1;

	return new Date(dateObj.setDate(first + 5));
};
