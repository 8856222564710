import { Button, Container, Table } from "@mantine/core";
import { format, isToday, isWeekend } from "date-fns";
import { useContext } from "react";

import { IconLock } from "@tabler/icons-react";
import { TimeReportContext } from "./context";
import { ReportNavigation } from "./navigation";
import { ProjectRow } from "./project-row";
import classes from "./report.module.css";

export const Report = () => {
	const timereportContext = useContext(TimeReportContext);

	if (!timereportContext) return null;

	const renderDateLabels = (dates: Date[]) => {
		return dates.map(date => {
			return (
				<Table.Th
					key={format(date, "yMd")}
					// Fix when holiday lib is in place
					// Transform style to mantine maybe https://mantine.dev/styles/create-styles/
					style={{
						textAlign: "center",
						background: isWeekend(date) ? "#402324" : "transparent",
						borderColor: isToday(date) ? "green" : "inherit",
						borderBottomWidth: isToday(date) ? "4px" : "1px",
						borderBottomStyle: "solid",
					}}
				>
					<div
						style={{
							position: "relative",
						}}
					>
						<div
							style={{
								position: "absolute",
								top: "-4px",
								right: "-2px",
							}}
						>
							{timereportContext.dateIsLocked(date) ? (
								<IconLock size={10} />
							) : null}
						</div>
						{format(date, "d")}
					</div>
					<span style={{ fontSize: "11px", display: "block" }}>
						{format(date, "E")}
					</span>
				</Table.Th>
			);
		});
	};

	return (
		<Container fluid p="xl">
			<div className={classes.tableWrapper}>
				<ReportNavigation />
				<Table
					styles={{
						td: {
							padding: "2px",
						},
					}}
					className={classes.table}
				>
					<Table.Thead>
						<Table.Tr>
							<Table.Th className={classes.firstColumn}>
								Project
							</Table.Th>
							{renderDateLabels(timereportContext.visibleDates)}
							<Table.Th>Total</Table.Th>
							<Table.Th />
							<Table.Th />
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody className={classes.tableBody}>
						{timereportContext.projects.map(project => (
							<ProjectRow project={project} key={project.id} />
						))}
					</Table.Tbody>
				</Table>
			</div>
			<div className={classes.tableFooter}>
				<div>
					<Button
						color="orange"
						className={classes.saveAction}
						onClick={timereportContext.lockCurrentPeriod}
						disabled={!timereportContext.hasUnlockedDays}
					>
						Lock
					</Button>
				</div>
				<Button
					className={classes.saveAction}
					onClick={timereportContext.saveCurrentPeriod}
					disabled={!timereportContext.hasUnlockedDays}
				>
					Save
				</Button>
			</div>
		</Container>
	);
};
