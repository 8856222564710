import { API_BASE_URL } from ".";

export const getToken = (email: string, password: string) => {
	return fetch(`${API_BASE_URL}/api/v1/auth/token`, {
		method: "GET",
		headers: {
			accept: "application/json",
			authorization: `Basic ${btoa(`${email}:${password}`)}`,
		},
	})
		.then(req => {
			if (!req.ok) {
				switch (req.status) {
					case 401:
						throw new Error("Fel inlogg. Försök igen!");
					default:
						throw new Error("Något gick snett.");
				}
			}

			return req;
		})
		.then(res => res.text());
};
