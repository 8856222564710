import { ActionIcon, Button, Group, Modal, Table } from "@mantine/core";
import {
	IconDeviceFloppy,
	IconPencil,
	IconTrash,
	IconX,
} from "@tabler/icons-react";
import type {
	AssignedProject,
	InputAssignedProjectsPut,
	Project,
	User,
} from "../../generated";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { DateInput } from "@mantine/dates";
import { CurrencyInput } from "../../components/currency-input";
import {
	deleteAssignProjects,
	hasRegisteredTime,
	putAssignProjects,
} from "../../api/assigned_projects";
import { useDisclosure } from "@mantine/hooks";

type IAssignedProjectRow = {
	project: Project;
	user: User;
	assignedProject: AssignedProject;
	onProjectUpdated?: () => void;
};

export const AssignedProjectRow = ({
	project,
	assignedProject,
	user,
	onProjectUpdated,
}: IAssignedProjectRow) => {
	const [editing, setEditing] = useState<boolean>(false);
	const [editedAssignedProject, setEditedAssignedProject] =
		useState<InputAssignedProjectsPut>(assignedProject);
	const [canDelete, setCanDelete] = useState<boolean>(false);
	const [opened, { open, close }] = useDisclosure(false);

	useEffect(() => {
		setEditedAssignedProject(assignedProject);
		hasRegisteredTime(assignedProject.id).then(setCanDelete);
	}, [assignedProject]);

	const handleDeleteProjectRow = () => {
		deleteAssignProjects(assignedProject.id)
			.then(close)
			.then(onProjectUpdated);
	};

	return (
		<Table.Tr>
			<Table.Td>
				{project.name} - {project.customer?.name}
			</Table.Td>
			<Table.Td>
				{editing ? (
					<DateInput
						clearable
						value={new Date(editedAssignedProject.start_date)}
						onChange={value => {
							if (!value) return;
							setEditedAssignedProject({
								...editedAssignedProject,
								start_date: format(value, "yyyy-MM-dd"),
							});
						}}
						placeholder="Start date"
					/>
				) : (
					format(assignedProject.start_date, "yyyy-MM-dd")
				)}
			</Table.Td>
			<Table.Td>
				{editing ? (
					<DateInput
						clearable
						value={
							editedAssignedProject.end_date === "infinity"
								? null
								: new Date(editedAssignedProject.end_date)
						}
						minDate={new Date(editedAssignedProject.start_date)}
						onChange={value => {
							setEditedAssignedProject({
								...editedAssignedProject,
								end_date: !value
									? "infinity"
									: format(value, "yyyy-MM-dd"),
							});
						}}
						placeholder="End date"
					/>
				) : assignedProject.end_date === "infinity" ? (
					"-"
				) : (
					format(assignedProject.end_date, "yyyy-MM-dd")
				)}
			</Table.Td>
			<Table.Td>{assignedProject.customer_billing_type}</Table.Td>
			<Table.Td>
				{editing ? (
					<CurrencyInput
						currency={editedAssignedProject.customer_currency}
						value={editedAssignedProject.customer_rate}
						onAmountChange={val =>
							setEditedAssignedProject({
								...editedAssignedProject,
								customer_rate: +val,
							})
						}
						onCurrencyChange={val =>
							setEditedAssignedProject({
								...editedAssignedProject,
								customer_currency: val,
							})
						}
					/>
				) : (
					`${assignedProject.customer_rate} ${assignedProject.customer_currency}`
				)}
			</Table.Td>
			{user?.user_type === "external_consultant" && (
				<>
					<Table.Td>
						{assignedProject.consultant_billing_type}
					</Table.Td>
					<Table.Td>
						{assignedProject.consultant_rate}{" "}
						{assignedProject.consultant_currency}
					</Table.Td>
				</>
			)}
			<Table.Td>
				{editing ? (
					<>
						<ActionIcon
							variant="transparent"
							onClick={() => {
								putAssignProjects(editedAssignedProject).then(
									() => {
										onProjectUpdated?.();
										setEditing(false);
									},
								);
							}}
						>
							<IconDeviceFloppy />
						</ActionIcon>
						<ActionIcon
							color="red"
							variant="transparent"
							onClick={() => {
								setEditing(false);
							}}
						>
							<IconX />
						</ActionIcon>
					</>
				) : (
					<>
						<ActionIcon
							variant="transparent"
							onClick={() => {
								setEditing(true);
							}}
						>
							<IconPencil />
						</ActionIcon>
						{canDelete && (
							<>
								<ActionIcon
									variant="transparent"
									onClick={open}
								>
									<IconTrash color="red" />
								</ActionIcon>
								<Modal
									centered
									opened={opened}
									onClose={close}
									withCloseButton={false}
									title="Are you sure?"
								>
									Are you sure you want to delete{" "}
									{project.customer?.name} - {project.name}{" "}
									from{" "}
									{format(
										assignedProject.start_date,
										"yyyy-MM-dd",
									)}{" "}
									to{" "}
									{assignedProject.end_date === "infinity"
										? "-"
										: format(
												assignedProject.end_date,
												"yyyy-MM-dd",
											)}{" "}
									assigned to {user.name}
									<Group mt="lg" justify="center">
										<Button
											onClick={handleDeleteProjectRow}
											color="red"
										>
											Delete
										</Button>
										<Button onClick={close}>
											No! Go back.
										</Button>
									</Group>
								</Modal>
							</>
						)}
					</>
				)}
			</Table.Td>
		</Table.Tr>
	);
};
